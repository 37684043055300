<template>
    <div>
        <card id="member-container">
            <div class="row mt-2">
                <div class="col">
                    <h6 class="m-0">Sent Messages</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 col-md-6 col-lg-4">
                    <fg-input class="no-border"
                              label="Search For Member by Last Name"
                              type="text"
                              v-model="searchCriteria" />
                </div>
                <div class="col-sm-4 col-md-6 col-lg-8">
                    <n-button round type="secondary" @click.native="search()" class="mt-4">
                        <i class="fas fa-search mr-2"></i> Search
                    </n-button>
                    <n-button round type="danger" @click.native="clearSearch()" class="ml-2 mt-4">
                        <i class="fas fa-times mr-2"></i> Clear
                    </n-button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <small v-if="area">
                        Below is a list of all messages processed by the site. If <b>TEST MODE</b> is active a message will
                      have a status <i class="fas fa-times-circle text-danger"></i>, indicating the message was processed but <b>NOT</b>
                      sent. When test mode is inactive and a message is processed, then actually sent, the message will have a status
                      <i class="fas fa-check-circle text-success"></i>.
                    </small>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <table class="results table">
                        <thead>
                        <tr>
                          <th style="width: 50px" class="text-center">Sent</th>
                          <th style="width: 110px">Timestamp</th>
                          <th style="width: 150px">View</th>
                          <th style="width: 300px" >Name</th>
                          <th style="width: 50px" class="text-center">Init</th>
                          <th style="width: 100px" >Method</th>
                          <th style="width: 200px" >Type</th>
                          <th>Sent To</th>
                        </tr>
                        </thead>

                        <tr v-for="message in messages" :key="message.id">
                            <td class="text-center">
                                <i v-if="message.sent" class="fas fa-check-circle text-success cursor-pointer"></i>
                                <i v-else class="fas fa-times-circle text-danger cursor-pointer"></i>
                            </td>
                            <td>{{ message.created_at | utcDisplay('MM/DD/YY HH:mm') }}</td>
                            <td>
                                <n-button round
                                          block
                                          class="bid-button small"
                                          type="success"
                                          @click.native="showMessage(message.id)">
                                   <i class="fas fa-envelope mr-2"></i> View Message
                                </n-button>
                            </td>
                            <td>{{ message.name }}</td>
                            <td class="text-center text-uppercase">{{ message.init }}</td>
                            <td>{{ message.method }}</td>
                            <td>{{ message.type }}</td>
                            <td>{{ message.to }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <el-pagination background
                           class="d-block mx-auto mt-3 text-center"
                           @current-change="page"
                           layout="prev, pager, next, jumper, ->, total, slot"
                           :current-page.sync="currentPage"
                           :page-size="50"
                           :total="pagination.total" />
        </card>
        <el-dialog title="Message Details" width="60%" :visible.sync="visible" append-to-body @close="closeMessage()">
          <div class="row justify-content-center mt-2">
              <div class="col-10" v-html="viewMessage"></div>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Dialog, Pagination } from 'element-ui'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MessageRepository = RepositoryFactory.get('message')

export default {
  name: 'messages',
  components: {
    [Dialog.name]: Dialog,
    [Pagination.name]: Pagination
  },
  data () {
    return {
      messages: [],
      pagination: {},
      currentPage: 1,
      cost: {},
      viewMessage: {},
      visible: false,
      searchCriteria: null
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      area: 'area/area'
    })
  },
  methods: {
    /** Retrieve messages from server */
    fetchMessages () {
      MessageRepository.index(this.facility.bid_year, this.currentPage)
        .then(r => {
          this.messages = _.pick(r.data.messages, 'data').data
          this.pagination = _.omit(r.data.messages, 'data')
          this.cost = r.data.cost
        })
    },
    /** View requested message */
    showMessage (messageID) {
      MessageRepository.get(messageID)
        .then(r => {
          this.viewMessage = r.data ? r.data : r
          this.visible = true
        })
    },
    closeMessage () {
      this.viewMessage = {}
      this.visible = false
    },
    page (page) {
      this.currentPage = page
      this.fetchMessages()
    },
    search () {
      MessageRepository.search(this.facility.bid_year, this.searchCriteria)
        .then(r => {
          this.messages = _.pick(r.data, 'data').data
          this.pagination = _.omit(r.data, 'data')
        })
    },
    clearSearch () {
      this.currentPage = 1
      this.searchCriteria = null
      this.fetchMessages()
    }
  },
  created () {
    this.fetchMessages()
  },
  mounted () {},
  watch: {}
}
</script>
<style></style>
